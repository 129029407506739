<ng-container *ngIf="vendorInvoiceListComponentService.refreshVendorInvoices$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceListComponentService.loadMoreVendorInvoice$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceListComponentService.resetOffset$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceSearchComponentService.search$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceSearchComponentService.refreshOnSearch$ | async"></ng-container>
<div dougsModalTitle>
  <h6>Attacher une facture d'achat</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div
  infiniteScroll
  [scrollWindow]="false"
  [immediateCheck]="true"
  (scrolled)="vendorInvoiceListComponentService.loadMoreVendorInvoice(false, false)"
  dougsModalContent="xlarge"
  class="no-padding force-max-height"
>
  <div class="search-bar-container mb-24">
    <dougs-search-bar
      [searchInProgress]="vendorInvoiceSearchComponentService.hasCurrentSearch$ | async"
      [formControl]="vendorInvoiceSearchComponentService.search"
      (clearSearch)="vendorInvoiceSearchComponentService.clearSearch()"
      [inModal]="true"
      size="small"
    ></dougs-search-bar>
  </div>
  <div class="px-32">
    <dougs-vendor-invoice-list
      *ngIf="!(vendorInvoiceComponentService.isLoading$ | async)"
      [isInModal]="true"
      [operation]="operation"
      [vendorInvoices]="vendorInvoiceStateService.vendorInvoices$ | async | orderByAttach: operation"
      [isSearching]="vendorInvoiceSearchComponentService.isSearching$ | async"
      (vendorInvoiceDetached)="detachVendorInvoice($event)"
      (vendorInvoiceAttached)="attachVendorInvoice($event)"
    ></dougs-vendor-invoice-list>
    <dougs-list-loading *ngIf="vendorInvoiceComponentService.isLoading$ | async"></dougs-list-loading>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button [dougsModalClose]="vendorInvoicesAttached">Terminer</dougs-button>
</div>
