import { Routes } from '@angular/router';
import { CanAccessToVendorInvoiceGuard } from '@dougs/vendor-invoice/shared';

export const VENDOR_INVOICE_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/vendor-invoice.component').then((c) => c.VendorInvoiceComponent),
    canActivate: [CanAccessToVendorInvoiceGuard],
  },
];
