import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  SearchBarComponent,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { ListLoadingComponent } from '../../components/vendor-invoice-body-loading/list-loading/list-loading.component';
import { VendorInvoiceListComponent } from '../../components/vendor-invoice-list/vendor-invoice-list.component';
import { OrderByAttachPipe } from '../../pipes/order-by-attach.pipe';
import { VendorInvoiceActionsComponentService } from '../../services/details/vendor-invoice-actions.component.service';
import { VendorInvoiceAddComponentService } from '../../services/list/vendor-invoice-add.component.service';
import { VendorInvoiceListComponentService } from '../../services/list/vendor-invoice-list.component.service';
import { VendorInvoiceSearchComponentService } from '../../services/list/vendor-invoice-search.component.service';
import { VendorInvoiceComponentService } from '../../services/vendor-invoice.component.service';

@Component({
  selector: 'dougs-attach-vendor-invoice-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalFooterDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalTitleDirective,
    ButtonComponent,
    SearchBarComponent,
    VendorInvoiceListComponent,
    ReactiveFormsModule,
    OrderByAttachPipe,
    ListLoadingComponent,
    InfiniteScrollDirective,
  ],
  templateUrl: './attach-vendor-invoice-modal.component.html',
  styleUrls: ['./attach-vendor-invoice-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    VendorInvoiceListComponentService,
    VendorInvoiceComponentService,
    VendorInvoiceSearchComponentService,
    VendorInvoiceAddComponentService,
    VendorInvoiceActionsComponentService,
  ],
})
export class AttachVendorInvoiceModalComponent {
  vendorInvoicesAttached: VendorInvoice[] = [];

  constructor(
    @Inject(MODAL_DATA) public operation: Operation,
    public readonly vendorInvoiceComponentService: VendorInvoiceComponentService,
    public readonly vendorInvoiceListComponentService: VendorInvoiceListComponentService,
    public readonly vendorInvoiceStateService: VendorInvoiceStateService,
    public readonly vendorInvoiceSearchComponentService: VendorInvoiceSearchComponentService,
  ) {}

  detachVendorInvoice(e: VendorInvoice): void {
    this.vendorInvoicesAttached = this.vendorInvoicesAttached.filter((vendorInvoice) => vendorInvoice.id !== e.id);
  }

  attachVendorInvoice(e: VendorInvoice): void {
    this.vendorInvoicesAttached = [...this.vendorInvoicesAttached, e];
  }
}
