import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';

@Pipe({
  name: 'orderByAttach',
  standalone: true,
})
export class OrderByAttachPipe implements PipeTransform {
  transform(vendorInvoices: VendorInvoice[], operation: Operation | null): VendorInvoice[] {
    if (!operation) {
      return vendorInvoices;
    }

    return [...vendorInvoices]?.sort(
      (a, b) =>
        +b.operations.some((operationIterated) => operationIterated.id === operation.id) -
        +a.operations.some((operationIterated) => operationIterated.id === operation.id),
    );
  }
}
